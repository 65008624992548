// material
import { Grid2, Container } from '@mui/material';
import { AllowedGuard } from '../hooks/use-allowed';
// import { CustomerOrdersStats } from '../components/document/orders/customer-orders-stats';
// import { Navigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import {
  // AppTasks,
  // AppNewUsers,
  PreVentaOrderPendigs,
  // AppItemOrders,
  // AppNewsUpdate,
  // AppWeeklySales,
  // AppOrderTimeline,
  // OrderOpenStatics,
  // AppWebsiteVisits,
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates,
  ProductsStatics,
  CustomersStatics,
  // AppLogRecent,
  PromoStatics
} from '../components/_dashboard/app';
// import { useAuth } from '../hooks/use-auth';
import { useApp } from '../hooks/use-app';
import GadminActions from '../contexts/gadmin-actions';

// ----------------------------------------------------------------------

const DashboardApp = () => {
  const { title } = useApp();

  return (
    <>
      <Page title={title('Dashboard')}>
        <Container maxWidth="zl">
          <Grid2 container spacing={3}>
            <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <PromoStatics />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <ProductsStatics />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <CustomersStatics />
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <PreVentaOrderPendigs />
              </Grid2>
            </AllowedGuard>
            {/* <Grid2 size={{ xs: 12, md: 6, lg: 8 }}>
              <AppLogRecent />
            </Grid2> */}
            {/* <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
                <OrderOpenStatics />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <CustomerReturnsStats />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
                <CustomerOrdersStats />
              </AllowedGuard>
            </Grid2> */}
          </Grid2>
        </Container>
      </Page>
    </>
  );
};
export default DashboardApp;
