import { dataLoader } from '../../../data-loader';

class GoalWarehousesApi {
  async getList({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/warehouses`,
      params: { companyId }
    });
    return response;
  }

  // sync
  async sync({ companyId }) {
    const response = await dataLoader.goalPost({
      action: `/inventory/warehouses/sync?companyId=${companyId}`,
      params: { companyId }
    });
    return response;
  }

  async getDetail({ companyId, warehouseId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/warehouses/${warehouseId}`,
      params: { companyId, warehouseId }
    });
    return response;
  }

  // get stock
  async getStock({ companyId, warehouseId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/warehouses/${warehouseId}/stock`,
      params: { companyId, warehouseId }
    });
    return response;
  }

  // get incomings
  async getIncomings({ companyId, warehouseId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/warehouses/${warehouseId}/incomings`,
      params: { companyId, warehouseId }
    });
    return response;
  }
}

export const goalWarehousesApi = new GoalWarehousesApi();
