import PropTypes from 'prop-types';
import { NumberColor } from '../../number-color';
import { GriLinkCell } from './grid-link-cell';
import GoalDateView from '../../goal-date-view';
import { Switch } from '@mui/material';
import { ColumnDataTypesEnum, getColumnDataTypeByType } from './grid-definitions';

export const numericTypes = ['total', 'price', 'discount', 'int', 'number', 'percent', 'rate'];

export const CustomGridCell = (props) => {
  const { value, row, column } = props;
  const { link, type } = column;
  let body = <>{value}</>;
  const isNumber = numericTypes.includes(type);
  if (isNumber) {
    const datatype = getColumnDataTypeByType(type || ColumnDataTypesEnum.NUMBER);
    const format = datatype.format || '0';
    body = <NumberColor value={value} format={format} withoutStyle />;
  }
  const isDate =
    column.type === ColumnDataTypesEnum.DATE || column.type === ColumnDataTypesEnum.DATETIME;
  if (isDate) {
    body = <GoalDateView value={value} />;
  }

  const isBoolean = column.type === ColumnDataTypesEnum.BOOLEAN;
  if (isBoolean) {
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    body = <Switch {...label} defaultChecked={value} color="secondary" disabled />;
  }

  if (link) {
    return (
      <GriLinkCell row={row} link={link}>
        {body}
      </GriLinkCell>
    );
  }

  return body;
};

CustomGridCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]),
  column: PropTypes.object,
  children: PropTypes.node,
  row: PropTypes.object
};
