import { useParams } from 'react-router-dom';
import { WarehouseIncomingsTable } from './warehouse-incomings-table';

const WarehouseIncomingsPanel = () => {
  const { id } = useParams();

  return <WarehouseIncomingsTable code={id} />;
};

export { WarehouseIncomingsPanel };
