const ColumnDataTypesEnum = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  PRICE: 'price',
  TOTAL: 'total',
  DISCOUNT: 'discount',
  PERCENT: 'percent',
  ID: 'id',
  INT: 'int',
  DATETIME: 'date',
  STATUS: 'status'
});

const ColumnDataType = Object.freeze([
  { code: ColumnDataTypesEnum.STRING, name: 'String', format: '' },
  { code: ColumnDataTypesEnum.NUMBER, name: 'Number', format: '#,##0' },
  { code: ColumnDataTypesEnum.DATE, name: 'Date', format: 'DD/MM/YYYY' },
  { code: ColumnDataTypesEnum.DATETIME, name: 'DateTime', format: 'DD/MM/YYYY HH:mm' },
  { code: ColumnDataTypesEnum.BOOLEAN, name: 'Boolean', format: '' },
  { code: ColumnDataTypesEnum.PRICE, name: 'Price', format: '0.0000' },
  { code: ColumnDataTypesEnum.TOTAL, name: 'Total', format: '#,##0.00' },
  { code: ColumnDataTypesEnum.DISCOUNT, name: 'Discount', format: '0.00%' },
  { code: ColumnDataTypesEnum.PERCENT, name: 'Percent', format: '0.00%' },
  { code: ColumnDataTypesEnum.ID, name: 'ID', format: '0' },
  { code: ColumnDataTypesEnum.INT, name: 'Int', format: '#,##0' }
]);

const getColumnDataTypeByType = (type) => {
  return ColumnDataType.find((column) => column.code === type);
};

export { ColumnDataTypesEnum, ColumnDataType, getColumnDataTypeByType };
